import React, { Fragment } from "react";

const SingleDepartmentRow = ({ department }) => {
  return (
    <Fragment>
      <div className="section is-lilac single-department-row tw-p-[1rem_1rem]">
        <div className="title !tw-text-[1.25rem] has-text-nero">{department.value}</div>
      </div>
    </Fragment>
  );
}

export default SingleDepartmentRow;
