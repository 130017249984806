import React, { Fragment } from "react";
import {LocalizedLink} from "src/components/commonComponents";

function SingleJobRow({ jobDetail, i }) {
  const srDescription = `for the ${jobDetail.title} position in ${jobDetail.location}`;
  return (
    <Fragment>
      <LocalizedLink
        to={`/about-us/careers/job-posting/${jobDetail.id}`}
      >
        <div className="section is-white-bg has-border-bottom-lilac single-job-row tw-p-[1rem_1rem]">
          <div
            className="columns is-mobile is-vcentered"
            key={jobDetail.title + i}
          >
            <div className="column is-10-desktop is-10-tablet is-9-mobile">
              <div className="tw-text-left !tw-text-[1.15rem] tw-mb-[0.5rem] is-spaced is-size-6-touch has-text-nero">
                {jobDetail.title}
              </div>
              <div className="subtitle !tw-text-[14.4px] has-text-nero">
                {jobDetail.location}
              </div>
            </div>

            <div className="column is-2-desktop is-2-tablet is-3-mobile">
              <div className="title is-size-4 is-size-6-touch has-text-nero is-flex is-align-items-center">
                <span className="apply tw-text-[1rem] tw-text-accruent_sapphire hover:tw-underline">
                  Apply &nbsp;
                  <span className="is-sr-only">{srDescription}</span>
                </span>
                <span className="icon tw-text-accruent_sapphire !tw-h-auto !tw-w-auto is-hidden-mobile">
                  <i className="fa fa-md fa-chevron-right tw-w-[12px] tw-h-[18px]" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </LocalizedLink>
    </Fragment>
  );
}

export default SingleJobRow;
