import React, { Fragment, useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Dropdown} from "src/components/commonComponents";
import SingleDepartmentRow from "./SingleDepartmentRow";
import SingleJobRow from "./SingleJobRow";
import { isEmpty } from "src/utils";
import "src/scss/components/_careerOpportunities.scss";

function CareerOpportunities(props) {

  const data = useStaticQuery(
    graphql`
      {
        allGreenhouseJobPost {
          nodes {
            details {
              title
              absolute_url
              internal_job_id
              id
              location {
                name
              }
              departments {
                name
              }
            }
          }
          totalCount
        }
      }
    `
  );

  //Setting a variable for the relevant data and the jobs count
  const relevantJobDataArray = data.allGreenhouseJobPost.nodes;
  const jobsCount = data.allGreenhouseJobPost.totalCount;

  const removeDuplicates = (array) => {
    //set only allows you to store unique values but converts the array into an object
    //below we have to convert the object back to an array
    const uniqueSet = new Set(array);
    const backToArray = [...uniqueSet];
    backToArray.sort();
    return backToArray.map((value) => ({ value, label: value }));
  };

  //Creating an array for locations and departments
  const allJobLocationList = [];
  const allJobsDepartmentsList = [];
  relevantJobDataArray.forEach((item) => {
    allJobLocationList.push(item.details.location.name.trim());
    item.details.departments.forEach((item) =>
      allJobsDepartmentsList.push(item.name.trim())
    );
  });

  //Removing the duplicates in the locations and departments array
  const filteredLocationList = removeDuplicates(allJobLocationList);
  const filteredDepartmentList = removeDuplicates(allJobsDepartmentsList);

  //Defining the jobObject. The Greenhouse returns a messy object.
  //The goal here is to create a clean object to work with.
  function jobObject(
    title,
    absolute_url,
    internal_job_id,
    id,
    location,
    department
  ) {
    this.title = title;
    this.url = absolute_url;
    this.internal_id = internal_job_id;
    this.id = id;
    this.location = location;
    this.department = department;
  }

  //Creating the array of clean job objects from above
  const makeJobsObjectArray = (n) => {
    const jobsArray = new Array(n);
    for (let i = 0; i < relevantJobDataArray.length; i++) {
      let title = "";
      let absolute_url = "";
      let internalJobId = "";
      let id = "";
      let location = "";
      let department = "";
      location = relevantJobDataArray[i].details.location.name.trim();
      title = relevantJobDataArray[i].details.title.trim();
      absolute_url = relevantJobDataArray[i].details.absolute_url.trim();
      internalJobId = relevantJobDataArray[i].details.internal_job_id;
      id = relevantJobDataArray[i].details.id;
      department = relevantJobDataArray[i].details.departments.map((item) =>
        item.name.trim()
      );

      jobsArray[i] = new jobObject(
        title,
        absolute_url,
        internalJobId,
        id,
        location,
        department
      );
    }
    return jobsArray;
  }

  //Calling the function above and setting it to a variable
  const cleanJobsArray = makeJobsObjectArray(jobsCount);

  //using useState is like this.state in a class component. https://reactjs.org/docs/hooks-state.html
  const [filters, setFilters] = useState({
    locationFilter: "",
    departmentFilter: "",
  });

  const [srFilterDescription, setSrFilterDescription] = useState(
    "No filters applied"
  );

  // Watcher to set screen reader description of filters changing
  useEffect(() => {
    const filterValues = Object.values(filters).filter((val) => !isEmpty(val));
    let descriptions = filterValues.join(", ");
    if (filterValues.length > 1) {
      const last = filterValues.pop();
      descriptions = filterValues.join(", ") + " and " + last;
    }
    if (!isEmpty(filterValues)) {
      const srDescription = `Filtered by ${descriptions}`;
      setSrFilterDescription(srDescription);
    } else {
      setSrFilterDescription("No filters applied");
    }
  }, [filters]);

  const locationRef = useRef();
  const departmentRef = useRef();
  const refs = {
    locationFilter: locationRef,
    departmentFilter: departmentRef,
  };
  // settings for pagination
  const topRef = useRef(null)
  const initialPageSize = 12
  const [pageSize, setPageSize] = useState(initialPageSize)
  let currentJobsCount = 0
  const loadMore = (isReset) => {
    if(isReset){
      setPageSize(initialPageSize)
      topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }else{
      setPageSize(pageSize + initialPageSize)
    }
  }
  const getCurrentDepartmentsAndJobs = (departmentList, isString) => {
    let count = 0
    let currentDepartmentList = []
    let currentJobsArray = []
    let totalcount = 0
    if (!props.isLoadMore) {
      currentDepartmentList = departmentList
      currentJobsArray = cleanJobsArray
      return { currentDepartmentList, currentJobsArray };
    }
    departmentList.some((item, i) => {
      if (count < pageSize)
        currentDepartmentList.push(item)
      const filteredJobArray = cleanJobsArray
        .filter((el) =>
          el.department?.some((key) => key === (isString ? item : item.value))
        )
      totalcount = totalcount + filteredJobArray.length
      if (count < pageSize)
        filteredJobArray.
          some(j => currentJobsArray.push(j) && count++ && count === pageSize)

    })
    currentJobsCount = totalcount
    return { currentDepartmentList, currentJobsArray };
  }

  // when a filter is selected in the dropdown, set the value selected into the empty strings in useState above
  const onSelectFilter = (filter, lead) => {
    if(props.isLoadMore){
      setPageSize(initialPageSize)
    }
    setFilters({
      ...filters,
      [lead]: filter,
    });
    refs[lead].current.setValue(filter);
  };

  // when a filter is removed in the dropdown, set the value selected to empty strings
  const onResetFilter = (lead) => {
    if(props.isLoadMore){
      setPageSize(initialPageSize)
    }
    setFilters({ ...filters, [lead]: "" });
    refs[lead].current.setValue(undefined);
  };

  const filteredContent = () => {
    let filteredDepartment = [filters.departmentFilter];
    let filteredLocation = [filters.locationFilter];

    if (filteredDepartment[0] && filteredLocation[0]) {
      const filteredJobArray = cleanJobsArray
        .filter((el) => el.location === filteredLocation[0])
        .filter((la) =>
          la.department?.some((item) => item === filteredDepartment[0])
        );
      currentJobsCount = filteredJobArray?.length
      return (
        <Fragment>
          <div>
            {filteredJobArray
              .slice(0, props.isLoadMore ? pageSize : currentJobsCount)
              .map((el, i) => {
                return (
                  <Fragment>
                    {el.department.map((dept) => (
                      <SingleDepartmentRow department={dept} key={dept + i} />
                    ))}
                    <SingleJobRow jobDetail={el} i={i} key={el.location + i} />
                  </Fragment>
                );
              })}
          </div>
        </Fragment>
      );
    } else if (filteredLocation[0]) {
      const filteredJobArray = cleanJobsArray
        .filter((el) => el.location === filteredLocation[0])
      currentJobsCount = filteredJobArray?.length
      return (
        <Fragment>
          {filteredJobArray
            .slice(0, props.isLoadMore ? pageSize : currentJobsCount)
            .map((el, i) => {
              return (
                <SingleJobRow jobDetail={el} i={i} key={el.location + i} />
              );
            })}
        </Fragment>
      );
    } else if (filteredDepartment[0]) {
      const { currentDepartmentList, currentJobsArray } = getCurrentDepartmentsAndJobs(filteredDepartment, true)
      return (
        <Fragment>
          {currentDepartmentList?.map((item, i) => (
            <div key={item.value + i}>
              <SingleDepartmentRow department={item} key={item + i} />
              {currentJobsArray
                .filter((el) => el.department?.some((key) => key === item))
                .map((el, i) => {
                  return <SingleJobRow jobDetail={el} i={i} key={(el, i)} />;
                })}
            </div>
          ))}
        </Fragment>
      );
    } else {
      const { currentDepartmentList, currentJobsArray } = getCurrentDepartmentsAndJobs(filteredDepartmentList)
      return (
        <Fragment>
          {currentDepartmentList?.map((item, i) => (
            <div key={item + i}>
              <SingleDepartmentRow department={item} />
              {currentJobsArray
                .filter((el) =>
                  el.department?.some((key) => key === item.value)
                )
                .map((el, i) => {
                  return (
                    <SingleJobRow jobDetail={el} i={i} key={el.location + i} />
                  );
                })}
            </div>
          ))}
        </Fragment>
      );
    }
  };
const filteredJobs = filteredContent()
  return (
    <Fragment>
      <div  ref={topRef} className="tw-font-bold career-opportunities tw-mt-[-44px] tw-mb-[9px]" id="careers">
        <div className="container">
          <div className="section">
          {!props.data?.fieldHideTitle &&  <h2 className="title tw-text-center tw-mb-[2rem] title-3">
               {props.data?props.data?.title:' Career Opportunities'}
            </h2> }
            {props.data?.body && (
            <div
              className="career-page-desc dangerously-html-handle"
              dangerouslySetInnerHTML={{ __html: props.data?.body?.value }}
            />
           )}
            <div className="columns">
              <div className="column">
                <Dropdown
                  options={filteredLocationList}
                  placeholder="All Locations"
                  lead="locationFilter"
                  onSelect={onSelectFilter}
                  onReset={onResetFilter}
                  ref={locationRef}
                />
              </div>
              <div className="column">
                <Dropdown
                  options={filteredDepartmentList}
                  placeholder="All Departments"
                  lead="departmentFilter"
                  onSelect={onSelectFilter}
                  onReset={onResetFilter}
                  ref={departmentRef}
                />
              </div>
            </div>
            <div className="is-sr-only" role="region" aria-live="polite">
              {srFilterDescription}
            </div>
            <p className="career-count tw-text-accruent_base_color tw-uppercase tw-font-medium !tw-text-[0.8rem]">
              {currentJobsCount} total open positions
            </p>
            {filteredJobs}
          </div>

        </div>
        {props.isLoadMore && currentJobsCount > initialPageSize &&
        <div onClick={() => loadMore(currentJobsCount <= pageSize)} className="is-size-6 is-size-6-touch is-flex is-align-items-end  is-justify-content-center load-more tw-cursor-pointer !tw-text-accruent_sapphire hover:tw-underline tw-mt-5">
          <span className="apply">
            {(currentJobsCount <= pageSize) ? 'Hide All' : 'Load More'}
          </span>
          <span >
          &#8201; ›
          </span>
        </div>
        }


      </div>
    </Fragment>
  );
}

export default CareerOpportunities;
